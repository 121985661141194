import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import EventWindow from './windows/event-window';
import CallsWindow from './windows/calls/CallsWindow';
import RackWindow from './windows/racks/RackWindow';
import SignalsWindow from './windows/signals/SignalsWindow';
import DoubleBoard from './windows/scoreboard/Double';
import SingleBoard from './windows/scoreboard/Single';
import CallError from './windows/errors/CallError';
import Denominations from './windows/denominations';
import Denominations2 from './windows/denominations2/denominations2';
import Settings from './windows/settings/settings';
import SettingsSIP from './windows/settingssip';
import FotoWindow from './windows/foto/foto';

const WindowVisibleController = styled.div`
  position: absolute;
  left: 20%;
  top: 15%;
  left: 0;
  top: 0;
  z-index: 999;
  width: 0;
  height: 0;
  ${(props) =>
    props.left &&
    css`
      left: ${props.left};
    `}
`;

class Windows extends React.Component {
  state = {
    order: [],
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.order.indexOf(nextProps.newWindow) === -1) {
      //console.log('wc get props', nextProps.newWindow)
      this.setState((state) => {
        let order = [...state.order];
        order.push(nextProps.newWindow);
        return {
          order: order,
        };
      });
    }
  }

  setActive = (id) => {
    //console.log('wc setActive', id)
    this.setState((state) => {
      let order = [...state.order];
      order = order.filter((e) => e !== id);
      order.push(id);
      return {
        order: order,
      };
    });
  };

  removeFromOrder = (id) => {
    //console.log('wc removeFromOrder', id)
    this.setState((state) => {
      let order = [...this.state.order];
      order = order.filter((e) => e !== id);
      return {
        order: order,
      };
    });
  };

  getWindows = () => {
    const windows = [];
    this.state.order.forEach((id) => {
      let item = this.props.windows[id];
      //console.log('wc windows', item)
      let coord = {};

      if (!item) {
        //this.removeFromOrder(id)
      } else {
        let Window = null;

        switch (item.type) {
          case 'foto':
            Window = FotoWindow;
            break;
          case 'event':
            Window = EventWindow;
            break;
          case 'calls':
            Window = CallsWindow;
            break;
          case 'rack':
            Window = RackWindow;
            break;
          case 'signals':
            Window = SignalsWindow;
            break;
          case 'denominations':
            Window = Denominations;
            break;
          case 'denominations2':
            Window = Denominations2;
            break;
          case 'double_board':
            Window = DoubleBoard;
            break;
          case 'single_board':
            Window = SingleBoard;
            break;
          case 'settings':
            Window = Settings;
            coord.defaultPosition = { x: 500, y: 0 };
            break;
          case 'settingssip':
            Window = SettingsSIP;
            coord.defaultPosition = { x: 500, y: 0 };
            //coord.left = '42%';
            break;
          case 'call_error':
            Window = CallError;
            break;
          default:
            throw new Error('Unknown window type');
        }

        windows.push(
          <WindowVisibleController key={'win_ev_' + id} onClick={() => this.setActive(item.id)}>
            <Window data={item} {...coord} />
          </WindowVisibleController>
        );
      }
    });

    return windows;
  };

  render() {
    return <div>{this.getWindows()}</div>;
  }
}

const mapStateToProps = (store) => {
  //console.log(store.windows)
  return {
    windows: store.windows.openedWindows,
    newWindow: store.windows.newWindow,
  };
};

export default connect(mapStateToProps)(Windows);
