import { store } from 'react-notifications-component';
import moment from 'moment';
import 'moment/locale/ru';

/**
 * notify
 * @param {*} title
 * @param {*} message
 * @param {*} type
 * @param {*} timeout
 * @returns
 */
export const notify = (title, message, type = 'danger', timeout = 2000) => {
  //console.log('error ~ 🚀:', message);
  if (message.code && message.code === 403) {
    return;
  }
  let msg = message;
  if (msg.message) {
    msg = msg.message;
  }
  store.addNotification({
    title: title,
    message: msg,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: timeout,
      onScreen: true,
    },
  });
};

/**
 * set period by variants
 * @param {*} period
 * @returns
 */
export const setPeriod = (period, action) => {
  let date = new Date();
  const endNowDay = new Date(date.setHours(23, 59, 59, 0));
  let firstDay = null;
  let lastDay = null;
  switch (period) {
    case 'currentMonth':
      firstDay = new Date(date.setDate(date.getDate() - 30));
      lastDay = endNowDay;
      break;
    case 'currentWeek':
      firstDay = new Date(date.setDate(date.getDate() - 7));
      lastDay = endNowDay;
      break;
    case 'lastThreeMonths':
      firstDay = new Date(date.setDate(date.getDate() - 90));
      lastDay = endNowDay;
      break;
    case 'today':
      firstDay = new Date(date.setHours(0, 0, 0, 0));
      lastDay = endNowDay;
      break;
    default:
      lastDay = '';
      firstDay = '';
  }
  return action({
    time_from: firstDay,
    time_to: lastDay,
  });
};

/**
 * prepare date for filter
 * @param {*} val
 * @returns
 */
export const prepareDate = (val) => {
  return val
    ? moment(val)
        .format('lll')
        .replace(/[г., ]{4}/, '')
        .replace(/\./, '')
    : null;
};

/**
 * get scale from size
 */
export const getScale = (size, forOther = false) => {
  if (!size.width || !size.height) {
    return 1;
  }

  if (forOther) {
    const w = size.width;
    if (w > 1279) {
      return 0.9;
    }
    if (w > 1023) {
      return 0.8;
    }
    if (w > 824) {
      return 0.7;
    }

    return 0.5;
  }

  const imgWidth = 1876;
  const imgHeight = 970;
  let scale = (size.width * 100) / imgWidth / 100;
  if (scale > 1) {
    scale = 1;
  }
  const dy = 100;
  if (size.height - dy < imgHeight * scale) {
    let scale2 = ((size.height - dy) * 100) / imgHeight / 100;
    if (scale2 < scale) {
      scale = scale2;
    }
  }
  return scale;
};

export const fakeWait = (counts: number) => {
  let k;
  const cnt = counts * 100000;
  for (let i = 0; i < cnt; i++) {
    k = i ** 9;
  }
  return k;
};

/**
 *
 * @param time ms
 * @returns
 */
export const wait = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(time), time);
  });
