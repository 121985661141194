import React from 'react';
import { connect } from 'react-redux';

import config from 'config';
import { data } from 'services/data';
import { openWindow } from '../../../store/actions/windows';
import Warning from '../../events/Warning';
import Error from '../../events/Error';
//import Signal from '../../events/Signal'
import Call from './Call';
import services from '../../../services';
import { RackWrapper, Barrier, IconTitle, Id } from './Rack.styled';

const replacer = config.replacer;
const captions = data.getCaptions();
// console.log('🚀 ~ captions:', captions);

function Rack(props) {
  //console.log('🚀 ~ Rack ~ props', props);
  function getIcon() {
    switch (props.type) {
      case 'single_rack':
        return <img src={'/images/single_rack.png'} alt="" />;
      case 'double_rack':
        return <img src={'/images/double_rack.png'} alt="" />;
      case 'payment_terminal':
        return <img src={'/images/payment_terminal.png'} alt="" />;
      case 'cashbox':
        return <img src={'/images/cashbox-icon.png'} alt="" />;
      case 'server':
        return <img src={'/images/data-server.png'} alt="" />;
      case 'goose':
        return <img src={'/images/goose.svg'} alt="" />;
      default:
        throw new Error('Rack type incorrect');
    }
  }

  function getBarriers() {
    switch (props.barriersCount) {
      case 2:
        return [<Barrier key="br_1" />, <Barrier key="br_2" />];
      case 1:
        return <Barrier />;
      case 0:
        return null;
      default:
        throw new Error('Wrong number of barriers');
    }
  }

  function showWindow() {
    const getOpenId = (caption, type, id) => {
      if (!replacer) {
        return id;
      }

      let out = id;
      let ok = false;
      switch (type) {
        case 'payment_terminal':
          const to = replacer.payments?.to;
          if (to) {
            out = captions.payments[to];
          }

          break;
        case 'single_rack':
          ok = false;
          replacer.racks.forEach((el) => {
            if (!ok) {
              if (el.captions) {
                if (el.captions.includes(caption)) {
                  out = captions.racks[el.to];
                  ok = true;
                }
              } else {
                out = captions.racks[el.to];
                ok = true;
              }
            }
          });
          break;
        default:
          out = id;
          break;
      }

      return out;
    };

    const openId = getOpenId(props.caption, props.type, props.id);
    const out =
      openId === props.id
        ? { id: openId, type: props.type, barriersCount: props.barriersCount, withSignals: !!props.events.length }
        : { ...window.config.nodes[openId], withSignals: !!props.events.length };
    // console.log('🚀 ~ showWindow ~ openId:', openId, props.type, props.id);

    props.openWindow({
      id: openId,
      type: 'rack',
      data: out, //{ id: openId, type: props.type, barriersCount: props.barriersCount, withSignals: !!props.events.length },
      header: services.getNodeTitle(props.type, props.id, props.caption),
    });
  }

  function showEvent() {
    const events = [...props.events];
    events.sort((first, second) => {
      if (first.type === 'critical' && second.type === 'notification') {
        return -1;
      }
      if (first.type === 'notification' && second.type === 'critical') {
        return 1;
      }
      if ((first.type === 'critical' && second.type === 'info') || (first.type === 'notification' && second.type === 'info')) {
        return -1;
      }
      if ((first.type === 'info' && second.type === 'critical') || (first.type === 'info' && second.type === 'notification')) {
        return 1;
      }

      return 0;
    });

    const event = events[0];
    let Component = null;
    switch (event.type) {
      case 'critical':
        Component = Error;
        break;
      case 'notification':
        Component = Warning;
        break;
      default:
        return null;
    }
    return <Component key={'ev_' + event.id} data={event} showWindow={showWindow} />;
  }

  function getViewRackId() {
    //console.log(props)
    if (props.caption) {
      return props.caption;
    }
    return props.type === 'cashbox' ? '' : window.service.call('getEquipmentInnerId', props.id);
  }

  function showCall() {
    return <Call info={props.call} />;
  }

  const cl = props.type;

  return (
    <RackWrapper>
      <RackWrapper onClick={() => showWindow()}>
        {props.barriersPosition === 'top' ? getBarriers() : null}
        <IconTitle className={cl}>
          {getIcon()}
          <Id className={cl}>{getViewRackId()}</Id>
        </IconTitle>
        {props.barriersPosition === 'bottom' ? getBarriers() : null}
      </RackWrapper>
      {props.events.length ? showEvent() : null}
      {props.call ? showCall() : null}
    </RackWrapper>
  );
}

const mapStateToProps = (store, props) => {
  const events = [];
  const rackEvents = store.events.events[props.id];
  for (let key in rackEvents) {
    let event = rackEvents[key];
    events.push(event);
  }

  // console.log('🚀 ~ rack id, rack call:', props.id, store.calls.calls[props.id]);
  return {
    events: events,
    call: store.calls.calls[props.id],
  };
};

export default connect(mapStateToProps, {
  openWindow,
})(Rack);
