export class Data {
  nodes = {};
  captions = {
    payments: {},
    racks: {},
  };

  /**
   * set nodes and righs from user
   * @param {*} config
   */
  setNodes(config) {
    //console.log('🚀 ~ setUserInfo ~ config:', config);
    const nodes = {};
    const captions = this.captions;
    config.objects.nodes &&
      config.objects.nodes.forEach((el) => {
        el.racks.forEach((el1) => {
          el1.node = el.id;
          nodes[el1.id] = el1;
          captions.racks[el1.caption] = el1.id;
        });
      });
    config.objects.payments &&
      config.objects.payments.forEach((el) => {
        if (nodes[el.id]) {
          console.log('error', el);
        } else {
          nodes[el.id] = el;
        }
        captions.payments[el.caption] = el.id;
      });
    config.objects.extensions &&
      config.objects.extensions.forEach((el) => {
        if (nodes[el.id]) {
          console.log('error', el);
        } else {
          nodes[el.id] = el;
        }
      });

    config.objects.gooses &&
      config.objects.gooses.forEach((el) => {
        if (nodes[el.id]) {
          console.log('error', el);
        } else {
          nodes[el.id] = el;
        }
      });

    this.nodes = nodes;
    this.captions = captions;
    return nodes;
  }

  /**
   * get nodes
   * @returns
   */
  getNodes() {
    return this.nodes;
  }

  /**
   * get captions
   * @returns
   */
  getCaptions() {
    return this.captions;
  }
}
