import styled from 'styled-components';

import { RedButton, BlueButton } from '../../../../shared/buttons';
import { ControlPanel } from '../styledComponents';

export const PaymentMeanButtons = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 30px;
  & > button {
    margin: 0 30px 0 0 !important;
    &:last-child{
      margin: 0 !important;
    }
  }
`;

export const ControlBlocks = styled.div`
  margin: 0 0px;
`;
export const StyledControlPanel = styled(ControlPanel)`
  padding: 30px 20px;
`;
export const OpenGateButton = styled(BlueButton)`
  img {
    margin: 0 10px;
  }
  width: 160px;
  height: 30px;
  display: flex;
`;
export const CloseGateButton = styled(RedButton)`
  img {
    margin: 0 10px;
  }
  width: 160px;
  height: 30px;
  display: block;
  margin-top: 40px;
`;
export const BlackButton = styled(OpenGateButton)`
  background: linear-gradient(#383c3f, #2f3235);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.8);
`;
export const ControlBlockHeader = styled.div`
  font-size: 15px;
  opacity: 0.45;
  color: #fff;
`;
export const ControlBlock = styled.div`
  padding: 15px 20px 0;
  box-sizing: border-box;
  border-radius: 8px;
  height: 170px;
  &:first-child {
    border-bottom: 1px solid #262b31;
  }
  &.gate {
    position: relative;
    background: #262b31;
  }
  .gate__title {
    color: #fff;
    font-weight: bold;
    opacity: 1;
  }
`;
export const ControlBlockButtons = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BigOpenGateButton = styled(OpenGateButton)`
  width: 210px;
  height: 45px;
`;
