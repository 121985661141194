import { LocalStorage } from 'libs/common';
import Service from './classes/Service';
import services from './services';

import './func_common';
import './func_calls';

window.config.network.timeout = 5000;

// change urls for development env
const test = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.REACT_APP_DEV === '1';
if (test) {
  window.config.localver = true;
  console.log('env: test');

  if (window.location.href.indexOf('localhost') !== -1) {
    // stage
    //config.network.api_url = 'http://10.15.252.234:1180/api';
    // window.config.network.api_url = 'http://10.15.252.234:3180/api';
    // window.config.network.socket_url = 'http://10.15.252.234:3180';
    // window.config.network.images_url = 'http://10.15.252.234:3080/photos/';

    // simp
    // window.config.network.api_url = 'https://mnemo.simferopol.stage.caps.smprc.ru/api';
    // window.config.network.socket_url = 'https://mnemo.simferopol.stage.caps.smprc.ru';
    // window.config.network.images_url = 'https://mnemo.simferopol.stage.caps.smprc.ru/photos/';

    // stockmann
    // window.config.network.api_url = 'https://mnemo.stockmann.stage.caps.smprc.ru/api';
    // window.config.network.socket_url = 'https://mnemo.stockmann.stage.caps.smprc.ru';
    // window.config.network.images_url = 'https://mnemo.stockmann.stage.caps.smprc.ru/photos/';

    // exhibition
    window.config.network.api_url = 'https://mnemo.demo.stage.caps.smprc.ru/api';
    window.config.network.socket_url = 'https://mnemo.demo.stage.caps.smprc.ru';
    window.config.network.images_url = 'https://mnemo.demo.stage.caps.smprc.ru/photos/';
    window.config.replacer = {
      payments: {
        to: '01',
      },
      racks: [{ captions: ['19', '20', '21', '22', '23', '24', '25'], to: '19' }, { to: '01' }],
    };

    // urengoy
    // window.config.network.api_url = 'https://mnemo.urengoy.stage.caps.smprc.ru/api';
    // window.config.network.socket_url = 'https://mnemo.urengoy.stage.caps.smprc.ru';
    // window.config.network.images_url = 'https://mnemo.urengoy.stage.caps.smprc.ru/photos/';

    // window.config.network.api_url = 'http://10.68.2.201:8080/api';
    // window.config.network.socket_url = 'http://10.68.2.201:8080';

    // sher
    // window.config.network.api_url = 'http://10.105.0.130:8080/api';
    // window.config.network.socket_url = 'http://10.105.0.130:8080';

    // gcup
    // window.config.network.api_url = 'https://mnemo.gcup.stage.caps.smprc.ru/api';
    // window.config.network.socket_url = 'https://mnemo.gcup.stage.caps.smprc.ru';

    // nu prod
    // window.config.network.api_url = 'http://10.112.26.2:8080/api';
    // window.config.network.socket_url = 'http://10.112.26.2:8080';
    // window.config.network.images_url = 'http://10.112.26.2:8080/photos/';

    // gcup
    // window.config.network.api_url = 'http://10.131.1.131:18068/api';
    // window.config.network.socket_url = 'http://10.131.1.131:18068';
    // window.config.network.images_url = 'http://10.131.1.131:18068/photos/';

    localStorage.debug = '';
    // window.config.theme = { body: { background: '#fff' }, header: { background: '#eee', color: '#333' } };
  }
}

// global services
window.dict = {};
window.service = new Service();
for (let key in services) {
  window.service.add(key, services[key]);
}

/**
 * set data from url
 */
const setTokenFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  window.rack_id = params.get('rack_id');
  window.zone_id = params.get('zone_id');
  if (token) {
    const storage = new LocalStorage('api');
    storage.setItem('token', token);
  }
};
setTokenFromUrl();

export const config = window.config;

export default config;
