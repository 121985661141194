import styled from 'styled-components'

export const Button = styled.button`
  cursor: pointer;
  height: 30px;
  padding: 0 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 0.8;
  color: #ffffff;
  border: none;
  outline: none;
  img {
    margin-right: 5px;
  }
`

export const SubmitButton = styled(Button)`
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  border-radius: 40px;
`
export const BlueSaveButton = styled(SubmitButton)`
  border: none;
  background-color: #3ea5ff;
  color: white;
  i {
    margin-right: 5px;
  }
`

/*___everything in the bottom must be refactored*/
export const ToOperationsButton = styled.div`
  cursor: pointer;
  width: 175px;
  height: 30px;
  background: linear-gradient(to top,#262b31,#49525db3);
  box-shadow: 0 1px 1px 0 #1d4a7b;
  border-radius: 20px;
  border: solid 1px #3ea5ff;
  p {
    font-size: 15px;
    margin: 0 auto;
  }
`
export const CloseButton = styled.div`
  cursor: pointer;
  width: 188px;
  height: 30px;
  background-color: #262b31;
  box-shadow: 0 1px 1px 0 #1d4a7b;
  border-radius: 40px;
  div {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-left: 3px;
      font-size: 16px;
      line-height: 0.75;
      color: #ffffff;
    }
  }
`
export const SaveButton = styled.div`
  outline: none;
  margin: 20px 0 0 auto;
  cursor: pointer;
  padding: 0 15px;
  height: 30px;
  background-color: #e0e0e0;
  box-shadow: 0 1px 1px 0 #1d4a7b;
  border-radius: 40px;
  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-left: 5px;
      font-size: 15px;
      line-height: 0.75;
      color: #000;
    }
  }
`
export const AddContact = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px;
  line-height: 1.73;
  color: #3ea5ff;
  i {
    margin-right: 5px;
  }
`
export const CreateNew = styled(SaveButton)`
  height: 30px;
  
`
export const Cross = styled.span`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
  &::before, &::after {
    position: absolute;
    left: 20px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #ffffff;
  }
`
export const AddRfid = styled.div`
  cursor: pointer;
  background-color: #3ea5ff;
  border-radius: 20px;
  width: 155px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff;
    margin-right: 5px;
  }
  p {
    color: #fff;
    font-size: 15px;
  }
`
export const RfidCancel = styled.div`
  cursor: pointer;
  width: 125px;
  height: 30px;
  background-color: #e0e0e0;
  box-shadow: 0 1px 1px 0 #1d4a7b;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`
//
export const BlueButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #3ea5ff;
  box-shadow: 0 1px 1px 0 #1d4a7b;
`

export const GrayButton = styled(Button)`
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  background-color: #e0e0e0;
  color: #000;
`