import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import ActiveCall from './widgets/ActiveCall'

function WidgetsController(props){

  function getWidgetByType(type){
    switch(type){
      case 'activeCall':
        return <ActiveCall key={ 'widget_' + type }/>
      default:
        throw new Error('Unknow widget type')
    }
  }

  function getWidgets(){
    return props.widgets.map((item) => {
      return getWidgetByType(item)
    })
  }

  return(
    <Fragment>
      {getWidgets()}
    </Fragment>
  )
}

const mapStateToProps = (store) => {
  return{
    widgets: store.widgets.activeWidgets
  }
}

export default connect(mapStateToProps)(WidgetsController)
