import { connect } from 'react-redux';

import { closeWindow } from 'store/actions/windows';
import WindowWrapper from 'components/shared/window/window-wrapper';
import { CallErrorWrapper, BigText, SmallText, Submit } from './CallError.styled';

function CallError(props) {
  return (
    <WindowWrapper>
      <CallErrorWrapper>
        <img src="/images/error2.png" alt="" />
        <div>
          <BigText>Ошибка соединения</BigText>
          <SmallText>Сервис звонков временно недоступен</SmallText>
          <Submit
            onClick={() => {
              props.closeWindow('call_error');
            }}
          >
            OK
          </Submit>
        </div>
      </CallErrorWrapper>
    </WindowWrapper>
  );
}

export default connect(null, {
  closeWindow,
})(CallError);
