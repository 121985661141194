import React, { Component } from 'react';
import { connect } from 'react-redux';

//import { setArchivedEvents } from './store/actions/eventsHistory';
import { setEvent, setEventsList, setUnrecognized } from './store/actions/events';
import { addWidget, removeWidget } from './store/actions/widgets';
import { openWindow } from './store/actions/windows';
import { setCallActive, setCall, dropCall } from './store/actions/calls';
import { updateStatus, updateStatusBg } from './store/actions/equipment';
import { setBoardsData } from './store/actions/boards';
import services from './services';
import { AppSocket } from 'app-socket';
// import Windows from './components/WindowsController';
// import Widgets from './components/WidgetsController';
// import Sounds from './components/SoundsController';
// import Unrecognaized from './components/Unrecognaized';
// import TopBar from './components/TopBar';
// import ZonesMap from './components/maps/ZonesMap';
import { AppMain } from './app-main';

class AppLogined extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    console.log('🚀 ~ app logined');

    // load active calls from localstorage ??
    window.loadActiveCalls();

    // init socket
    this.initSocket();

    // start
    window.clearActiveCalls();

    // open windows
    this.checkOpenWindows();

    this.setState({ ready: true });
  }

  /**
   * init socket
   */
  initSocket = () => {
    const {
      setEvent,
      setCallActive,
      addWidget,
      removeWidget,
      openWindow,
      setCall,
      dropCall,
      updateStatus,
      updateStatusBg,
      setEventsList,
      setBoardsData,
      setUnrecognized,
      calls,
      unrecognized,
    } = this.props;
    const params = {
      setEvent,
      setCallActive,
      addWidget,
      removeWidget,
      openWindow,
      setCall,
      dropCall,
      updateStatus,
      updateStatusBg,
      setEventsList,
      setBoardsData,
      setUnrecognized,
      calls,
      unrecognized,
    };
    this.appSocket = new AppSocket(params);
  };

  componentDidUpdate(prevProps) {
    const notEqual = (v1, v2) => {
      return JSON.stringify(v1) !== JSON.stringify(v2);
    };
    if (notEqual(this.props.calls, prevProps.calls) || notEqual(this.props.unrecognized, prevProps.unrecognized)) {
      this.appSocket.update(this.props.calls, this.props.unrecognized);
    }
  }

  /**
   * check for open windows by external params
   */
  checkOpenWindows = () => {
    // open windows on external parameters
    if (window.rack_id) {
      const node = window.config.nodes[window.rack_id];
      if (node) {
        const title = services.getNodeTitle(node.type, node.id, node.caption);
        this.props.openWindow({
          id: node.id,
          type: 'rack',
          data: {
            id: node.id,
            type: node.type,
            barriersCount: node.barriersCount,
          },
          header: title,
        });
      }
      window.rack_id = null;
    }
    if (window.zone_id) {
      const node = window.config.objects.zones.find((item) => {
        return item.zoneId.toString() === window.zone_id.toString();
      });
      //console.log('node',node);
      if (node) {
        this.props.openWindow({
          id: '0',
          type: 'single_board',
          data: { zoneId: node.zoneId, color: node.color },
        });
      }
      window.zone_id = null;
    }
  };

  /**
   * render
   * @returns
   */
  render() {
    if (!this.state.ready) {
      return null;
    }
    const { unrecognized } = this.props;
    return <AppMain unrecognized={unrecognized} dropCall={this.props.dropCall} />;
  }
}

const mapStateToProps = (store) => {
  return {
    calls: store.calls.calls,
    unrecognized: store.events.unrecognized,
  };
};

export default connect(mapStateToProps, {
  addWidget,
  removeWidget,
  openWindow,
  setBoardsData,
  updateStatus,
  updateStatusBg,
  setEvent,
  setEventsList,
  setCall,
  dropCall,
  setCallActive,
  setUnrecognized,
  //setArchivedEvents,
})(AppLogined);
