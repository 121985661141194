import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
`;

export const Place = styled.div`
  position: relative;
  background: #333;
`;
export const RectorPlace = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9999;
`;

export const Buttons = styled.div`
  display: flex;
  margin: 3px 0;
  padding: 10px 0;
  .btn {
    margin-right: 20px;
  }

  position: relative;
  &.offline1::after,
  &.loading1::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
  }
  &.loading1::after {
    background: #393e44;
  }
  &.offline1::before {
    content: 'Отключен';
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: normal;
    width: 240px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin: -15px 0 0 -120px;
    z-index: 11;
  }
  &.loading1::before {
    content: '';
    z-index: 11;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(/images/loader.svg) no-repeat 50%;
    background-size: 60px auto;
  }
`;

export const Info = styled.div`
  margin: 10px 0 0;
  color: #fff;
`;
