import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//import { socket } from 'api';
import { Place, Line, Head } from './RackState.styled';
import RackStateItem from './RackStateItem';
import { titles } from './RackState.consts';

class RackState extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      state: undefined,
    };

    this.timer = null;
  }

  componentDidMount() {
    this._mounted = true;
    window.socket.on('get_state_background', this.updateState);
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.timer) {
      clearInterval(this.timer);
    }
    window.socket.off('get_state_background', this.updateState);
  }

  updateState = (msg) => {
    if (this._mounted && msg.target === this.props.id) {
      this.setState({ state: msg });
    }
  };

  getTitle = (name) => {
    return titles[name] ? titles[name].title : name;
  };

  getInfo = (name) => {
    return titles[name] ? titles[name].info : name;
  };

  /**
   * get line
   * @param {*} obj
   */
  getLine = (obj, out, gate = false) => {
    const items = [];
    // const id = this.props.id;

    Object.keys(titles).forEach((el) => {
      if (obj[el] !== undefined && obj[el] !== null) {
        items.push({ title: this.getTitle(el), info: this.getInfo(el), active: obj[el], name: el });
      }
    });
    //console.log('🚀 ~ Object.keys ~ items', items, obj);
    let outed = false;
    const els = items.map((el, idx) => {
      const { title, info, active } = el;

      let cl = '';
      if (el.name.indexOf('relay_sema_') !== -1) {
        if (!outed) {
          outed = true;
          cl = 'outed';
        }
      }
      return <RackStateItem className={cl} key={idx} title={title} info={info} active={active} />;
    });

    if (els.length) {
      if (gate) {
        out.push(<Head key={`gate`}>Шлюз</Head>);
      }
      out.push(<Line key={`line${gate}`}>{els}</Line>);
    }
  };

  /**
   * get lines
   * @returns
   */
  getItems = () => {
    const { gooseMode } = this.props;
    const state = this.state.state ? this.state.state : this.props.state;
    if (!state || !Object.keys(state).length || (!gooseMode && !state.mode)) {
      return null;
    }

    const out = [];
    if (state.controller) {
      this.getLine(state.controller, out);
    }

    if (state.gateway) {
      this.getLine(state.gateway, out, true);
    }
    return out;
  };

  render() {
    const items = this.getItems();
    if (!items || !items.length) {
      return null;
    }

    return <Place>{items}</Place>;
  }
}

RackState.propTypes = {
  sendControlMsg: PropTypes.func.isRequired,
  timeOut: PropTypes.number.isRequired,
  gooseMode: PropTypes.bool,
};

RackState.defaultProps = {
  timeOut: 3000,
};

const mapStateToProps = (store, props) => {
  const state = store.equipment[props.id];
  return {
    state: state || {},
  };
};

export default connect(mapStateToProps)(RackState);
