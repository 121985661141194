import React from 'react';
import { connect } from 'react-redux';

class SoundsController extends React.Component {
  state = {
    activeSounds: [],
    newSounds: [],
  };

  audios = {};

  componentWillReceiveProps(nextProps) {
    // concat and make only uneque values
    let sounds = [...nextProps.sounds];
    this.setState({ newSounds: sounds }, () => {
      this.manageSounds();
    });
  }

  manageSounds = () => {
    this.checkForNew();
    setTimeout(() => {
      this.checkForOld();
      this.setState({
        activeSounds: this.state.newSounds.slice(0),
      });
    }, 150);
  };

  checkForNew = () => {
    const newSounds = this.state.newSounds.filter((element) => {
      return this.state.activeSounds.indexOf(element) < 0;
    });

    newSounds.forEach((item) => {
      let newAudio = new Audio('/audio/' + item + '.mp3');
      newAudio.loop = true;
      try {
        newAudio.play();
      } catch (e) {
        console.log('🚀 ~ sound play error:', e);
      }
      this.audios[item] = newAudio;
    });
  };

  checkForOld = () => {
    const oldSounds = this.state.activeSounds.filter((element) => {
      return this.state.newSounds.indexOf(element) < 0;
    });

    oldSounds.forEach((item) => {
      if (this.audios[item]) {
        this.audios[item].pause();
      }
      delete this.audios[item];
    });
  };

  render() {
    return <span />;
  }
}

const mapStateToProps = (store) => {
  return {
    sounds: store.sounds.sounds,
  };
};

export default connect(mapStateToProps)(SoundsController);
